<template>
  <div id="tuan">
    <van-sticky :offset-top="0">
      <div class="header">
        <van-row gutter="10">
          <van-col span="10" class="header-title" @click="onClickLeft">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
            />出团管理
          </van-col>
          <van-col span="14" class="search">
            <input
              type="text"
              class="search-input"
              v-model="query.list_name"
              @blur="onRefresh"
            />
            <svg-icon
              :data_iconName="'search'"
              :className="'search-input-icon'"
            />
          </van-col>
        </van-row>
      </div>
    </van-sticky>
    <div class="tuan-body">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_travel_lists"
        >
          <div
            class="tuan-body-item"
            v-for="(item, index) in list"
            :key="index"
            @click="onItemClick(item)"
          >
            <van-row class="tuan-body-header">
              <van-col span="6">
                <van-image class="item-avatar" round :src="item.image_url" />
              </van-col>
              <van-col span="18">
                <div class="item-name">
                  {{ item.list_name }}
                </div>
                <div class="item-sub-text">电话：{{ item.guide_mobile }}</div>
                <div class="item-sub-text">{{item.guide_role_name}}：{{ item.guide }}</div>
              </van-col>
            </van-row>
            <van-row class="tuan-info">
              <van-col span="10">
                <div class="tuan-start-time text-left">
                  {{ item.start_date }}
                </div>
                <div class="tuan-start-address text-left">
                  {{ item.start_addr ? item.start_addr : "暂无" }}
                </div>
              </van-col>
              <van-col span="4">
                <div class="need-time">{{ item.days }}天</div>
                <div class="tuan-items" @click.stop="onShowClick(item)">
                  行程景点
                </div>
              </van-col>
              <van-col span="10">
                <div class="tuan-end-time text-right">{{ item.end_date }}</div>
                <div class="tuan-end-address text-right">
                  {{ item.end_addr ? item.end_addr : "暂无" }}
                </div>
              </van-col>
              <van-col span="24">
                <div class="start-date">出发日期：{{ item.start_date }}</div>
                <div class="tuan-ren-number">
                  团队人数： {{ item.tourists_count ? item.tourists_count : 0 }} 人 
                </div>
                <div class="tuan-ren-number">状态：{{ item.status_text }}</div>
                <div class="tuan-ren-number">备注：{{ item.note }}</div>
              </van-col>
            </van-row>

            <div class="circle-left"></div>
            <div class="circle-right"></div>
          </div>
        </van-list>
        <van-empty v-if="list.length == 0&&loading==false" description="暂无数据" />
      </van-pull-refresh>
    </div>
    <van-overlay z-index="100" :show="show" @click="show = false">
      <div class="wrapper">
        <h6 class="text-center wrpper-title-large">行程景点</h6>
        <van-row style="margin-top: 20px">
          <van-col span="3" class="wrpper-title text-left">景点</van-col>
          <van-col span="7" class="wrpper-title text-center">到达</van-col>
          <van-col span="7" class="wrpper-title text-center">出发</van-col>
          <van-col span="7" class="wrpper-title text-right">逗留</van-col>
        </van-row>
        <van-row
          style="margin-top: 10px"
          v-for="(item, index) in nodes"
          :key="index"
        >
          <van-col span="3" class="wrpper-text text-left van-ellipsis">{{
            item.node_name
          }}</van-col>
          <van-col span="7" class="wrpper-text text-center van-ellipsis">{{
            item.start_time ? item.start_time.slice(5, 16) : "--"
          }}</van-col>
          <van-col span="7" class="wrpper-text text-center van-ellipsis">{{
            item.end_time ? item.end_time.slice(5, 16) : "--"
          }}</van-col>
          <van-col span="7" class="wrpper-text text-right van-ellipsis">{{
            item.stay_time ? item.stay_time : "--"
          }}</van-col>
        </van-row>
        <van-empty
          image="error"
          v-if="nodes.length == 0"
          description="暂无景点"
        />
      </div>
    </van-overlay>
    <Tarbar :active="3"></Tarbar>
  </div>
</template>
<script>
import Tarbar from "../components/Tarbar.vue";
export default {
  name: "tuan",
  components: {
    Tarbar
  },
  data() {
    return {
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
      qrcodeShow: true,
      query: {
        page: 1,
        list_name: "",
      },
      nodes: [],
      show: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/");
    },
    async get_travel_list(id) {
      const res = await this.$apis.get_travel_list(id);
      this.nodes = res.data.travel_plan_nodes;
      this.show = true;
    },
    onShowClick(item) {
      this.get_travel_list(item.id);
    },
    onItemClick(item) {
      this.$router.push({
        path: "/tuan/item",
        query: {
          id: item.id,
        },
      });
    },
    async get_travel_lists() {
      const res = await this.$apis.get_travel_lists(this.query);
      if (res.next_page) {
        this.query.page++;

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
     
      this.list = [...this.list, ...res.data]
    },
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_travel_lists();
    },
  },
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#tuan {
  height: 100vh;
  /* padding-top:10vh; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.tuan-body {
  height: 90vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0.4rem;
  overflow-y: scroll;
  padding-bottom: 6rem;
  position: fixed;
  top: 10vh;
  left: 0;
  width: 100vw;
  /* margin-top: 10vh; */
}
.tuan-body-item {
  background: #ffff;
  border-radius: 20px;
  width: 100%;
  height: 5.2rem;
  position: relative;
  padding: 0.2rem;
  margin-bottom: 10px;
  z-index: 10;
}
.tuan-body-item .item-avatar {
  overflow: hidden;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
}
.tuan-body-item .item-avatar img {
  height: 100%;
  height: 100%;
}
.tuan-body-item .item-name {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.tuan-body-item .item-sub-text {
  color: #666;
  font-size: 12px;
}
.tuan-body-item .item-agency-text {
  font-size: 14px;
  color: #666;
}
.lang {
  background: rgba(148, 174, 64, 0.18);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #94ae40;
  font-size: 12px;
  margin-left: 10px;
}
.lang2 {
  background: rgba(75, 183, 78, 0.13);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #4bb74e;
  font-size: 12px;
  margin-left: 10px;
}
.tuan-info {
  margin-top: 0.6rem;
  padding: 0 0.2rem;
}
.tuan-start-time,
.tuan-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.tuan-start-address,
.tuan-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.tuan-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.circle-left {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-color: #436ef0;
  z-index: 10;
  top: 1.4rem;
  left: -0.2rem;
  border-radius: 50%;
}
.circle-right {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-image: linear-gradient(
    179deg,
    #6c94f6 0%,
    #436ef0 16%,
    #3a5be9 100%
  );
  z-index: 10;
  top: 1.4rem;
  right: -0.2rem;
  border-radius: 50%;
}
.start-date {
  font-size: 12px;
  color: #666666;
  margin-top: 20px;
}
.tuan-ren-number {
  font-size: 12px;
  color: #666666;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.wrapper {
  width: 90vw;
  margin: 0 auto;
  background: #fff;
  border-radius: 20px;
  border-radius: 20px;
  height: 60vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0.3rem;
  overflow-y: scroll;
}
.wrpper-title {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}
.wrpper-text {
  color: #666;
  font-size: 12px;
}
.tuan-body-header {
  height: 1.4rem;
  border-bottom: 1px dashed #f3f3f3;
  padding: 0 0.2rem;
}
</style>
