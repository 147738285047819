<template>
  <div id="tabbar">
    <van-row gutter="0" class="tabbar-container">
      <van-col span="4" class="tab-item" v-for="(item,index) in menus" :key="index" @click="onMenuClick(item)">
        <svg-icon :data_iconName="active==index?item.activeicon:item.icon" :className="active==index?'tab_icon_active':'tab_icon'" />
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "Tarbar",
  props:{
    active:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      menus: [
        {
          text: "旅行社管理",
          icon: "agency-default",
          activeicon: "agency-active",
          path: "/agency",
        },
        {
          text: "导游管理",
          icon: "guide-default",
          activeicon:"guide-active",
          path: "/guider",
        },
        {
          text: "行程管理",
          icon: "trip-default",
          activeicon:"trip-active",
          path: "/trip",
        },
        {
          text: "出团管理",
          icon: "tuan-default",
          activeicon:"tuan-active",
          path: "/tuan",
        },
        {
          text: "事件通知",
          icon: "message-default",
          activeicon:"message-active",
          path: "/emergencies",
        },
        {
          text: "资讯发布",
          icon: "issue-default",
          activeicon:"issue-active",
          path: "/issue/list",
        },
        // {
        //   text: "星级管理",
        //   icon: "guide-default",
        //   activeicon:"guide-active",
        //   path: "/startManage",
        // },
      ],
    };
  },
  components: {},
  methods: {
    onMenuClick(item) {
      this.$router.push(item.path);
    },
  },
};
</script>

<style>
#tabbar{
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  z-index: 100;
}
.tabbar-container{
  background: #fff;
  height: 1.2rem;
  width: 100%;
  line-height: 1.4rem;
  box-shadow: 0 -2px 14px 0 rgba(182,179,179,0.64);
  border-radius: .6rem;
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  z-index: 100;
 
}
.tab-item{
  text-align: center;
}
.tab_icon{
 font-size: .8rem;
}
.tab_icon_active{
 font-size: .9rem;
}
</style>
